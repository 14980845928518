import { useState, useEffect } from 'react';

interface UsePaginationConfig {
  currentPage: number;
  perPage: number;
}

export function usePagination<T>(
  items: T[],
  config: UsePaginationConfig = { currentPage: 1, perPage: 6 }
) {
  const [currentPage, setCurrentPage] = useState(config?.currentPage ?? 1);
  const [numberPerPage, setNumberPage] = useState(config?.perPage ?? 6);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [idxOfLastItem, setIdxOfLastItem] = useState(
    currentPage * numberPerPage
  );
  const [idxOfFirstItem, setIdxOfFirstItem] = useState(
    idxOfLastItem - numberPerPage
  );
  const currentItems = items?.slice(idxOfFirstItem, idxOfLastItem) ?? [];
  useEffect(() => {
    setIdxOfLastItem(currentPage * numberPerPage);
  }, [currentPage, numberPerPage]);
  useEffect(() => {
    setIdxOfFirstItem(idxOfLastItem - numberPerPage);
  }, [idxOfLastItem, numberPerPage]);
  useEffect(
    function calculatePageNumbers() {
      const pages = new Array(Math.ceil(items.length / numberPerPage)).fill(0);
      setPageNumbers(pages?.map((_, index) => index + 1));
    },
    [items?.length, numberPerPage]
  );
  return {
    currentPage,
    setCurrentPage,
    numberPerPage,
    setNumberPage,
    pageNumbers,
    items: currentItems,
  } as const;
}
