import React from 'react';
import Swal from 'sweetalert2';
import axios, { AxiosResponse } from 'axios';
import { validateString } from 'avilatek-utils';
import { EMAIL_TYPES } from '../lib/constants';

export default function ContactUs() {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [profession, setProfession] = React.useState('Ingeniero');

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name: _name, value } = e.currentTarget;
    switch (_name) {
      case 'email':
        setEmail(value);
        break;
      case 'profession':
        setProfession(value);
        break;
      case 'message':
        setMessage(value);
        break;
      case 'name':
        setName(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!validateString(name) && (name?.length ?? 0) > 128) {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: 'Por favor, ingrese un valor para el campo nombre',
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      if (!validateString(email) && (email?.length ?? 0) > 128) {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: 'Por favor, ingrese un valor para el campo correo',
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      if (!validateString(message) && (message?.length ?? 0) > 2048) {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: 'Por favor, ingrese un valor para el campo mensaje',
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      if (!validateString(profession) && (profession?.length ?? 0) > 128) {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: 'Por favor, ingrese un valor para el campo profesión',
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      const {
        data: { success },
      } = await axios.post<any, AxiosResponse<{ success: boolean }>>(
        '/api/send-email',
        {
          template: EMAIL_TYPES.FORM_SUBMIT,
          name,
          email,
          message,
          profession,
        }
      );
      if (success) {
        setEmail('');
        setMessage('');
        setName('');
        setProfession('Ingeniero');
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Su mensaje ha sido enviado con exito',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: 'Ha ocurrido un error al envia su mensaje',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        text: 'Ha ocurrido un error al envia su mensaje',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="container h-auto">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <div className="section-title text-center pb-30">
            <h3 className="title text-white">Contáctanos</h3>
          </div>
        </div>
      </div>
      <br />
      <form onSubmit={handleSubmit} method="POST">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                id="input-contact"
                placeholder="NOMBRE"
                className="form-control"
                value={name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="input-contact"
                placeholder="EMAIL"
                className="form-control"
                value={email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <select
                name="profession"
                id="input-contact"
                className="form-control"
                placeholder="PROFESION"
                onChange={handleChange}
                value={profession}
              >
                <option value="Arquitecto">Arquitecto</option>
                <option value="Diseñador">Disenador</option>
                <option value="Ingeniero">Ingeniero</option>
                <option value="Otro">Otro</option>
              </select>
            </div>
          </div>
          <div className="col-md-6 h-auto">
            <div className="form-group">
              <textarea
                name="message"
                id="input-contact"
                rows={8}
                className="form-control"
                placeholder="MENSAJE"
                value={message}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-100">
            <div
              className="container"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                type="submit"
                className="btn btn-fill btn-wd mb-2 w-25 mt-5"
                style={{ backgroundColor: '#DAA520' }}
              >
                ENVIAR
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
