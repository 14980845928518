import React from 'react';
import { NavbarUser, Sidebar, Footer } from '..';

interface UserLayoutProps {
  children: React.ReactNode;
}

export default function UserLayout({ children }: UserLayoutProps) {
  return (
    <div className="wrapper" id="body">
      <Sidebar />
      <div className="main-panel ps ps--active-y">
        <NavbarUser />
        <div id="content">
          <br />
          <div className="container-fluid">{children}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
