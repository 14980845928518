import React from 'react';
import Swal from 'sweetalert2';
import { ref, update } from 'firebase/database';
import { useRouter } from 'next/router';
import { database } from '../lib/firebase';
import { useUser } from '../hooks';

export default function EditProfile() {
  const router = useRouter();
  const [user] = useUser();
  const [phone, setPhone] = React.useState('');
  const datosPersonales = React.useMemo(
    () => [
      {
        className: 'col-md-6',
        input: {
          placeholder: user?.firstName,
          type: 'text',
          className: 'form-control',
          name: 'first_name',
          disabled: true,
        },
        label: 'Primer nombre',
      },
      {
        className: 'col-md-6',
        input: {
          placeholder: user?.secondName,
          type: 'text',
          className: 'form-control',
          name: 'second_name',
          disabled: true,
        },
        label: 'Segundo nombre',
      },
      {
        className: 'col-md-6',
        input: {
          placeholder: user?.firstLastname,
          type: 'text',
          className: 'form-control',
          name: 'last_name',
          disabled: true,
        },
        label: 'Primer apellido',
      },

      {
        className: 'col-md-6',
        input: {
          placeholder: user?.secondName,
          type: 'text',
          className: 'form-control',
          name: 'secondlast_name',
          disabled: true,
        },
        label: 'Segundo apellido',
      },
      {
        className: 'col-md-6',
        input: {
          placeholder: user?.dni,
          type: 'text',
          className: 'form-control',
          name: 'dni',
          disabled: true,
        },
        label: 'Cédula',
      },
      {
        className: 'col-md-6',
        input: {
          placeholder: user?.phone,
          type: 'text',
          className: 'form-control',
          name: 'number_phone',
          disabled: false,
        },
        label: 'Número de teléfono',
      },
    ],
    [user]
  );

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // eslint-disable-next-line prefer-regex-literals
    const regex2 = new RegExp('^[0-9]*$');
    if (regex2.test(phone) && phone.length === 11) {
      await update(ref(database, `users/${user?.idUser}`), { phone });
      await Swal.fire({
        icon: 'success',
        text: 'Los cambios se han aplicado correctamente',
      });
      router.reload();
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Los datos suministrado estan erroneos',
      });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.currentTarget;
    switch (name) {
      case 'number_phone':
        setPhone(value);
        break;

      default:
        break;
    }
  };

  return (
    <div className="card">
      <div className="card-header text-center">
        <h5>Editar el perfil</h5>
      </div>
      <div className="card-content">
        <form method="POST">
          <div className="row">
            {datosPersonales.map((dato) => (
              <div className={dato.className} key={dato.label}>
                <div className="form-group">
                  <label htmlFor={dato.input.name}>{dato.label}</label>
                  {dato.input.disabled ? (
                    <input
                      type={dato.input.type}
                      placeholder={dato.input.placeholder}
                      className={dato.input.className}
                      name={dato.input.name}
                      id={dato.input.name}
                      disabled
                    />
                  ) : (
                    <input
                      type={dato.input.type}
                      placeholder={dato.input.placeholder}
                      className={dato.input.className}
                      name={dato.input.name}
                      onChange={onChange}
                      id={dato.input.name}
                      required
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="card-footer">
            <br />
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="button"
                  className="btn btn-default btn-fill mb-2"
                  onClick={handleSubmit}
                  style={{ float: 'right' }}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
