/* eslint-disable @next/next/no-img-element */
import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useRouter } from 'next/router';
import { ref, get, push, update, set } from 'firebase/database';
import { Product } from '../models';
import { useProduct, useUser } from '../hooks';
import { database } from '../lib/firebase';
import { EMAIL_TYPES } from '../lib/constants';

type Props = {
  product: Product;
};

export default function CardProduct({ product }: Props) {
  const router = useRouter();
  const [user] = useUser();
  const [, setProduct] = useProduct();
  const [coinsTeam, setCoinsTeam] = React.useState(0);
  const date = new Date();
  let quantity = 1;
  React.useEffect(function syncCoins() {
    const query = async () => {
      const snapshot = await get(ref(database, 'habitatTeamAmount'));
      if (snapshot.exists()) {
        setCoinsTeam(snapshot.val() ?? 0);
      }
    };
    query();
  }, []);

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      if (user.points < product.points) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No tiene los puntos suficientes para canjear este producto',
        });
        return;
      }
      const result = await Swal.fire({
        title: 'Estas seguro?',
        text: 'desea canjear este producto?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, canjear',
      });
      if (result.value) {
        const results = await Swal.fire({
          title: 'Indique la cantidad que desea canjear del producto',
          input: 'number',
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: (cantidad) => {
            if (cantidad > 0) {
              quantity = cantidad;
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El numero ingresado debe ser mayor a 1',
              });
              return null;
            }
            return quantity;
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
        const productCoins = quantity * (product?.points ?? 0);
        if (results.value && user?.points >= productCoins) {
          Swal.fire({
            icon: 'success',
            text: `${user?.firstName} ${user?.firstLastname}, el producto ha sido canjeado exitosamente`,
          });
          let _codigo = '';
          const caracteres = '0123456789';
          for (let i = 0; i < 6; i += 1) {
            const number = Math.random();
            const rand = Math.floor(number * (caracteres?.length ?? 0));
            _codigo += caracteres.substring(rand, rand + 1);
          }
          const pointsNow = user.points - productCoins;
          setProduct({
            idProduct: null,
            image: product.image,
            title: product.title,
            description: product.description,
            points: productCoins,
            numberExchange: _codigo,
            categories: product.categories,
          });

          push(ref(database, 'exchanges'), {
            id_user: user?.id,
            product: product.title,
            points: productCoins,
            date: `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`,
            number_exchange: date.getFullYear() + _codigo,
            quantity,
          });
          const coinsTeamUpdate = coinsTeam + productCoins;
          await set(ref(database, 'habitatTeamAmount'), coinsTeamUpdate);
          await update(ref(database, `users/${user?.idUser}`), {
            points: pointsNow,
          });
          const {
            data: { success },
          } = await axios.post<any>('/api/send-email', {
            template: EMAIL_TYPES.REDEEM_PRODUCT,
            client: user?.firstName ?? '',
            product: product?.title ?? '',
            email: user.email,
          });
          if (success) {
            await router.push('/products/receipt');
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No tiene los puntos suficientes para canjear la cantidad que establecio para este producto',
          });
        }
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al intentar canjear este producto',
      });
    }
  };
  return (
    <div className="container" id="products">
      <div className="card">
        <img
          src={product?.image}
          alt={`product_${product?.title}`}
          style={{
            width: '100%',
            height: '200px',
            objectFit: 'cover',
            objectPosition: 'center',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
          className="card-img-top"
        />
        <br />
        <div className="card-content">
          <h6 className="text-center">{product.title}</h6>
          <div className="row justify-content-center">
            <div className="bootstrap-tagsinput">
              {product?.categories?.map((val) => (
                <span className="tag label label-info" key={val.value}>
                  {val?.label ?? 'No hay categoria'}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="card-footer">
          <div className="row justify-content-center">
            <h5 className="text-center py-3">
              Habitat Coins: {Number(product.points).toLocaleString('de-DE')}
            </h5>
          </div>
          <div className="row justify-content-center">
            <button
              className="btn btn-default"
              style={{ float: 'right' }}
              onClick={onClick}
              type="button"
            >
              Canjear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
