/* eslint-disable @next/next/no-img-element */
import React from 'react';

export default function Separator() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="separator-container" id="separator-container">
          <div className="separator line-separator">
            <img src="/assets/img/separator.png" alt="separator" />
          </div>
        </div>
      </div>
    </div>
  );
}
