// eslint-disable-next-line no-use-before-define
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 100 : -100,
    opacity: 0,
    zIndex: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 100 : -100,
    opacity: 0,
  }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) =>
  Math.abs(offset) * velocity;

interface SliderProps {
  images: string[];
  controls?: boolean;
}

export default function Slider({ images = [], controls = false }: SliderProps) {
  const [[page, direction], setPage] = React.useState<[number, number]>([0, 0]);
  const imageIndex = wrap(0, images?.length ?? 0, page);

  const paginate = React.useCallback(
    (newDirection: number) => {
      setPage([page + newDirection, newDirection]);
    },
    [page]
  );

  const timeout = React.useRef<NodeJS.Timeout>(null);

  React.useEffect(
    function autoMove() {
      const SLIDE_TIME = 5 * 1000; // 15s in ms
      const id = setInterval(() => {
        paginate(1);
      }, SLIDE_TIME);
      timeout.current = id;
      return () => {
        if (timeout.current) {
          clearInterval(timeout.current);
        }
      };
    },
    [paginate]
  );

  return (
    <div
      className=""
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'item-center',
      }}
    >
      <AnimatePresence initial={false} custom={direction}>
        <div
          id="Header"
          className="header-hero bg_cover"
          style={{ position: 'absolute', height: '100%' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="header-hero-content text-left"
                  style={{
                    marginTop: '70px',
                  }}
                >
                  <h2
                    className="header-title text-white"
                    style={{
                      borderLeft: '0.5rem solid white',
                      paddingLeft: '1rem',
                    }}
                    id="texto-banner"
                  >
                    Bienvenido al Market Place de Habitat Venezuela.{' '}
                    <span
                      className="text-white py-3"
                      style={{ fontSize: '1.5rem' }}
                      id="parrafor-banner"
                    >
                      Un espacio virtual diseñado para nuestros clientes.
                      Registrando tus compras en esta plataforma, acumularás
                      Habitat Coins que podrán ser canjeados por productos o
                      servicios especiales. Queremos dar un trato especial a
                      quien más se lo merece. Solo debes registrarte y seguir
                      los pasos #PisandoFirme
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <motion.img
          className="imgBanner"
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            alignContent: 'center',
          }}
          key={page}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          animate="center"
          transition={{
            x: {
              type: 'spring',
              stiffness: 350,
              damping: 50,
              duration: 0.1,
            },
            opacity: 0.1,
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={() => {
            const swipe = swipePower(10, 10);
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
    </div>
  );
}
