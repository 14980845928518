import React from 'react';

type Props = {
  text: string;
};

export default function Spinner({ text }: Props) {
  return (
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <br />
      <br />
      <br />
      <br />
      <h6 style={{ marginTop: '5px' }}>{text}</h6>
    </div>
  );
}
