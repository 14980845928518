/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';

type Props = {
  image: string;
};

const links = [
  {
    name: 'HOME',
    path: '/',
  },
  {
    name: 'PASOS',
    id: 'steps',
  },
  {
    name: 'CONÓCENOS',
    id: 'about',
  },
  {
    name: 'CONTÁCTANOS',
    id: 'contact',
  },
  {
    name: 'REGISTRARSE',
    path: '/sign-up',
  },
  {
    name: 'INGRESAR',
    path: '/sign-in',
  },
];

function Navbar({ image }: Props) {
  const [isOpen, setOpen] = React.useState(false);
  const toggler = React.useRef<HTMLButtonElement>(null);
  const navbar = React.useRef<HTMLDivElement>(null);

  const toggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isOpen === false) {
      toggler?.current?.setAttribute('aria-expanded', 'true');
      navbar?.current?.classList?.add('show');
      toggler?.current?.classList?.remove('collapsed');
      toggler?.current?.classList?.add('active');
      setOpen(true);
    } else {
      toggler?.current?.setAttribute('aria-expanded', 'false');
      toggler?.current?.classList?.remove('active');
      toggler?.current?.classList?.add('collapsed');
      navbar?.current?.classList?.remove('show');
      setOpen(false);
    }
  };

  const closeNavbar = (e) => {
    document.getElementById('toggler').setAttribute('aria-expanded', 'false');
    document.getElementById('toggler').classList.remove('active');
    document.getElementById('toggler').classList.add('collapsed');
    document.getElementById('navbar').classList.remove('show');
    setOpen(false);
  };

  return (
    <div className="navbar-area" id="navigation">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg" style={{ height: 110 }}>
              <div className="navbar-brand">
                <img src={image} alt="logo" className="logo" />
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-controls="navbar"
                  aria-label="toggle navigation"
                  aria-expanded="false"
                  onClick={toggle}
                  id="toggler"
                  ref={toggler}
                >
                  <span className="toggler-icon" style={{ color: 'white' }} />
                  <span className="toggler-icon" style={{ color: 'white' }} />
                  <span className="toggler-icon" style={{ color: 'white' }} />
                </button>
              </div>
              <div
                className="navbar-collapse sub-menu-bar collapse"
                id="navbar"
                ref={navbar}
              >
                <ul className="navbar-nav ml-auto" id="nav">
                  {links.map((link) => (
                    <li className="nav-item" key={link.name}>
                      {link.path ? (
                        link.name === 'INGRESAR' ? (
                          <Link
                            href={link.path}
                            className="page-scroll px-4"
                            style={{
                              color: '#DAA520',
                              border: '0.16rem solid #DAA520',
                            }}
                          >
                            {link.name}
                          </Link>
                        ) : (
                          <Link
                            href={link.path}
                            className="page-scroll"
                            style={{ color: 'white' }}
                          >
                            {link.name}
                          </Link>
                        )
                      ) : (
                        <Link
                          href={`/#${link.id}`}
                          className="page-scroll"
                          style={{ color: 'white' }}
                        >
                          {link.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
