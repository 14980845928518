import React from 'react';

type Props = {
  text: string;
  img: string;
  section: string;
};

export default function Section({ text, img, section }: Props) {
  return (
    <div className={section}>
      <div className="row">
        <div className="col-md-6" />
        <div className="col-md-6">
          <h5 className="text-center">{text}</h5>
        </div>
      </div>
    </div>
  );
}
