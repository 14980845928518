/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Swal from 'sweetalert2';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, update } from 'firebase/database';
import { storage, database } from '../lib/firebase';
import { useUser } from '../hooks';
import { User } from '../models';

type Props = {
  user: User;
};

export default function CardProfile({ user }: Props) {
  const [, setUser] = useUser();
  const uploadImage = () => {
    const inputClick = document.getElementById('uploadImage');
    inputClick.click();
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0] ?? null;
    if (file) {
      const result = await uploadBytes(
        ref(storage, `userPhotos/${encodeURIComponent(file.name)}`),
        file
      );
      const url = await getDownloadURL(result.ref);
      await update(dbRef(database, `users/${user?.idUser}`), { photo: url });
      setUser((_user) => ({ ..._user, photo: url }));
      Swal.fire({
        icon: 'success',
        text: 'Los cambios se han aplicado correctamente',
      });
    }
  };

  return (
    <div className="card card-user">
      <div className="image">
        <img
          src="https://res.cloudinary.com/dckoavcwj/image/upload/v1556306972/Bella_Bianco_100x100_pza.jpg"
          alt="fondo de carta de perfil"
        />
      </div>
      <div className="card-content">
        <div className="author">
          <div
            onClick={uploadImage}
            role="button"
            key="upload"
            aria-hidden="true"
            tabIndex={0}
          >
            <img
              src={
                user?.photo && user?.photo !== ''
                  ? user?.photo
                  : '../../assets/img/faces/face-profile.jpg'
              }
              style={{
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              alt="imagen de perfil"
              id="imageProfile"
            />
          </div>
          <input
            type="file"
            style={{ display: 'none' }}
            id="uploadImage"
            name="uploadImage"
            onChange={onChange}
          />
          <h4 className="card-title" style={{ marginTop: 17 }}>
            {user?.firstName.toLocaleUpperCase() ?? ''}{' '}
            {user?.firstLastname.toLocaleUpperCase() ?? ''}
          </h4>
        </div>
      </div>
      <div className="card-footer py-3">
        <div className="text-center">
          <div className="row">
            <div className="col-md-5 offset-md-4">
              <p>
                Habitat Coins:
                <strong>{user?.points.toLocaleString('es-ES') ?? ''}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
