/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import {
  Document,
  Page,
  Text,
  Image,
  View,
  PDFViewer,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { User, Product } from '../models';
import { Spinner } from '.';

type Props = {
  product: Product;
  user: User;
};

export default function ReceiptPdf({ product, user }: Props) {
  const date = new Date();
  const sello = React.useMemo(() => '../../assets/img/HcoinsBlack.png', []);
  const logo = React.useMemo(
    () => '../../assets/img/logo-marketplace-black.png.png',
    []
  );
  const dni = React.useMemo(() => Number(user?.dni), [user?.dni]);

  return (
    <>
      {user && product ? (
        <PDFViewer
          style={{
            width: '100%',
            height: '500px',
          }}
        >
          <Document title={`constancia_${user?.firstName}_${product?.title}`}>
            <Page
              wrap={false}
              size="A4"
              style={{
                paddingTop: 35,
                paddingHorizontal: 35,
                borderBottom: 1000,
                borderBottomColor: 'black',
              }}
            >
              <View
                style={{
                  textAlign: 'right',
                  fontSize: 10,
                  marginTop: 5,
                }}
              >
                <Text>
                  N° Confirmacion: {date.getFullYear()}
                  {product?.numberExchange}
                </Text>
              </View>
              <View
                style={{
                  width: 150,
                  height: 50,
                  marginVertical: 30,
                  position: 'relative',
                  left: 191,
                  top: 20,
                }}
              >
                <Image src={logo} fixed />
              </View>
              <View
                style={{
                  textAlign: 'center',
                  fontSize: 15,
                  marginBottom: 10,
                }}
              >
                <Text>Constancia</Text>
              </View>
              <View
                style={{
                  margin: 15,
                  fontSize: 15,
                  textAlign: 'justify',
                }}
              >
                <Text>
                  Por medio de esta constancia, se hace validez el canjeo del
                  producto: {product?.title ?? ''}
                  por el usuario: {user?.firstName ?? ''}{' '}
                  {user?.firstLastname ?? ''}, con la cedula{' '}
                  {dni.toLocaleString('es-ES')}
                </Text>
              </View>
              <View>
                <Image
                  src={sello}
                  style={{
                    width: 100,
                    height: 100,
                    alignContent: 'center',
                    left: 220,
                    position: 'relative',
                    top: 50,
                  }}
                  fixed
                />
              </View>
              <View
                style={{
                  textAlign: 'center',
                  marginTop: 160,
                  fontSize: 13,
                  color: 'grey',
                  left: 8,
                }}
              >
                <Text>{dayjs(date).format('DD/MM/YYYY HH:mm:ss')}</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      ) : (
        <div className="container" style={{ height: '100vh' }}>
          <div className="row justify-content-center">
            <div style={{ position: 'relative', top: '300px' }}>
              <Spinner text="Cargando..." />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
