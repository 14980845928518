/* eslint-disable @next/next/no-img-element */
import React from 'react';
import {
  applyPolyfills,
  defineCustomElements,
} from '@deckdeckgo/qrcode/dist/loader';

type Props = {
  name: string;
  lastName: string;
  email: string;
};

export default function CreditCard({ name, lastName: lastname, email }: Props) {
  const qrRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (qrRef.current) {
      qrRef.current.innerHTML = `
    <deckgo-qrcode content="https://habitat-points-admin.now.sh/selling_points/point_of_sell?email=${email}" style="position:relative;left:30px;width:100px;heigth:100px;">
    </deckgo-qrcode>`;
    }
  }, [email]);

  React.useEffect(() => {
    let windowMounted = false;
    if (!windowMounted) {
      applyPolyfills().then(() => {
        defineCustomElements(window);
      });
    }
    return () => {
      windowMounted = true;
    };
  }, []);

  return (
    <div className="cards">
      <input type="checkbox" id="card1" className="more" aria-hidden="true" />
      <div className="content">
        <div className="front">
          <div className="inner">
            <img
              className="card__front-square card__square"
              src="https://image.ibb.co/cZeFjx/little_square.png"
              alt="chip_creditcard"
            />
            <img
              className="card__front-logo card__logo"
              src="https://habitat-venezuela.now.sh/assets/img/habitat/logo/logoHabitat.png"
              alt="Logohabitat"
            />
            I
            <p style={{ fontSize: '1rem' }} id="email_creditcard">
              {' '}
              <strong style={{ color: 'white' }}>
                ******
                {email.slice(email.indexOf('@') - 3, email.indexOf('com') + 3)}
              </strong>
            </p>
            <label htmlFor="card1" className="button" aria-hidden="true">
              Codigo QR
            </label>
          </div>
          <div className="card__space-75">
            <span className="card__label text-white">Dueño</span>
            <p className="card__info">
              {name} {lastname}
            </p>
          </div>
        </div>
        <div className="back">
          <div className="inner">
            <div className="card__black-line" />
            <div className="row justify-content-center">
              <div className="col-lg-12" id="qr" ref={qrRef} />
            </div>
            <label htmlFor="card1" className="button return" aria-hidden="true">
              Volver
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
