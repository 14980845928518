import React from 'react';

export default function Footer() {
  return (
    <footer
      className="footer"
      style={{
        backgroundColor: '#17181d',
        paddingBottom: '2rem',
        width: 'auto',
        height: 'auto',
        paddingTop: '1rem',
      }}
    >
      <div className="container-fluid">
        <div className="copyright pull-center">
          <a className="text-white">
            {`© ${new Date().getFullYear()} HMundo Group, Desarrollado por Avila Tek`}
          </a>
        </div>
      </div>
    </footer>
  );
}
