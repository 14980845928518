import React from 'react';
import Swal from 'sweetalert2';
import { ref, onValue, push, update } from 'firebase/database';
import { database } from '../lib/firebase';
import { useUser } from '../hooks';

export default function InfoExtra() {
  const [user] = useUser();
  const [instagram, setInstagram] = React.useState('');
  const [university, setUniversity] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [productFav, setProductFav] = React.useState('');
  const [products, setProducts] = React.useState([]);
  const [infoEvents, setInfoEvents] = React.useState('Si');
  const [projects, setProjects] = React.useState('Si');
  const [arqDis, setArqDis] = React.useState('Si');
  const [fiscalData, setFiscalData] = React.useState('');
  const [locationWork, setLocationWork] = React.useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    push(ref(database, `users/${user.idUser}/infoExtra`), {
      instagram,
      university,
      contact,
      productFav,
      infoEvents,
      projects,
      fundacionArdDis: arqDis,
      fiscalData,
      locationWork,
    });
    await Swal.fire({
      position: 'top-end',
      text: 'Tus datos se han enviado',
      showConfirmButton: false,
      timer: 1500,
    });
    update(ref(database, `users/${user.idUser}`), { answer: true });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    switch (name) {
      case 'instagram':
        setInstagram(value);
        break;
      case 'university':
        setUniversity(value);
        break;
      case 'share_contact':
        setContact(value);
        break;
      case 'product_fav':
        setProductFav(value);
        break;
      case 'info_events':
        setInfoEvents(value);
        break;
      case 'arq_dis':
        setArqDis(value);
        break;
      case 'fiscal_company':
        setFiscalData(value);
        break;
      case 'know_projects':
        setProjects(value);
        break;
      case 'location_work':
        setLocationWork(value);
        break;
      default:
        break;
    }
  };

  React.useEffect(function syncProducts() {
    const _products = [];
    const unsubscribe = onValue(ref(database, 'products'), (snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((snap) => {
          _products.push(snap.val());
        });
      }
      setProducts(_products?.sort((a, b) => (a.name > b.name ? -1 : 1)) ?? []);
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <div className="card">
      <div className="card-header text-center">
        <h5>Preguntas extras</h5>
      </div>
      <div className="card-content">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="instagram">Instagram</label>
                <input
                  type="text"
                  placeholder="@habitatpoints"
                  className="form-control"
                  name="instagram"
                  onChange={handleChange}
                  id="instagram"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="university">Lugar de estudio</label>
                <input
                  type="text"
                  placeholder="Universidad Central"
                  className="form-control"
                  name="university"
                  onChange={handleChange}
                  id="university"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="share_contact">
                  Donde habias oido hablar de nuestra marca:
                </label>
                <input
                  type="text"
                  placeholder=""
                  className="form-control"
                  name="share_contact"
                  onChange={handleChange}
                  id="share_contact"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="product_fav">
                  Producto favorito de nuestra marca:
                </label>
                <select
                  name="product_fav"
                  id="product"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  {products.map((product) => (
                    <option value={product.name} key={product.name}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="info_events">
                  Le gustaria informarse sobre eventos patrocinados por Habitat
                  Venezuela
                </label>
                <select
                  name="info_events"
                  id="info_events"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  <option value="si">Si</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="know_project">
                  Conoces sobre nuestros proyectos de apoyo al turismo y la
                  educacion
                </label>
                <select
                  name="know_project"
                  id="know_project"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  <option value="si">Si</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="arq_dis">
                  Conoces sobre nuestro plan de fidelizacion y recocimiento para
                  arquitectos y disenadores
                </label>
                <select
                  name="arq_dis"
                  id="arq_dis"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  <option value="si">Si</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="fiscal_company">Rif / C.I</label>
                <input
                  type="text"
                  name="fiscal_company"
                  className="form-control"
                  onChange={handleChange}
                  id="fiscal_company"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="location_work">Cuidad y Zona de oficina</label>
                <input
                  type="text"
                  name="location_work"
                  placeholder="Caracas"
                  className="form-control"
                  onChange={handleChange}
                  id="location_work"
                  required
                />
              </div>
            </div>
          </div>
          <button
            className="btn btn-default"
            style={{ float: 'right' }}
            type="submit"
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}
