/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';
import { AiFillShop } from 'react-icons/ai';
import { MdFileUpload } from 'react-icons/md';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaExchangeAlt, FaRegCheckSquare } from 'react-icons/fa';

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebar-wrapper ps ps--active-y" id="sidebar">
        <div className="sidebar-header">
          <div className="row justify-content-center">
            <img
              src="/assets/img/logo-marketplace.png"
              alt="logo"
              style={{
                width: 170,
                height: 50,
                alignContent: 'center',
              }}
            />
          </div>
        </div>
        <ul className="nav">
          <li>
            <Link
              href="/products"
              style={{
                color: 'white',
                fontSize: '17px',
              }}
            >
              <span style={{ padding: '10px' }}>
                <AiFillShop style={{ color: 'white' }} />
              </span>
              Market Place
            </Link>
          </li>
          <li>
            <Link
              href="/user/exchanges"
              style={{
                color: 'white',
                fontSize: '17px',
              }}
            >
              <span style={{ padding: '10px' }}>
                <FaExchangeAlt style={{ color: 'white' }} />
              </span>
              Transacciones
            </Link>
          </li>
          <li>
            <Link
              href="/user/exchange"
              style={{
                color: 'white',
                fontSize: '17px',
              }}
            >
              <span style={{ padding: '10px' }}>
                <MdFileUpload style={{ color: 'white' }} />
              </span>
              Solicitud de Habitat Coins
            </Link>
          </li>
          <li>
            <Link
              href="/user/request"
              style={{
                color: 'white',
                fontSize: '17px',
              }}
            >
              <span style={{ padding: '10px' }}>
                <FaRegCheckSquare style={{ color: 'white' }} />
              </span>
              Status de Habitat Coins
            </Link>
          </li>
          <li>
            <Link
              href="/user"
              style={{
                color: 'white',
                fontSize: '17px',
              }}
            >
              <span style={{ padding: '10px' }}>
                <BsFillPersonFill style={{ color: 'white' }} />
              </span>
              Perfil
            </Link>
          </li>
        </ul>
        <div id="footer-sidebar">
          <h6
            className="text-white m-auto text-center"
            style={{ fontSize: '0.7rem' }}
          >
            {`© ${new Date().getFullYear()} HMundo Group, Desarrollado por`}{' '}
            <a href="https://avilatek.com">Avila Tek</a>
          </h6>
        </div>
      </div>
    </div>
  );
}
