import React from 'react';

export default function Header() {
  return (
    <header className="header-area">
      <div id="Header" className="header-hero bg_cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="header-hero-content text-left"
                style={{
                  marginTop: '70px',
                }}
              >
                <h2
                  className="header-title text-white"
                  style={{
                    borderLeft: '0.5rem solid white',
                    paddingLeft: '1rem',
                  }}
                >
                  TEXTO QUE RESUMA Y LA FUNCION Y MOTIVE A LOS USUARIOS A
                  REGISTRARSE O INGRESAR EN LA PLATAFORMA
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
