/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signOut } from 'firebase/auth';
import { FaSignOutAlt } from 'react-icons/fa';
import { useUser } from '../hooks';
import { auth } from '../lib/firebase';

function NavbarUser() {
  const router = useRouter();
  const [user, setUser] = useUser();
  const [isOpen, setOpen] = React.useState(false);
  const navbar = React.useRef<HTMLDivElement>(null);
  const togglerUser = React.useRef<HTMLButtonElement>(null);
  const onClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    signOut(auth);
    setUser(null);
    await router.push('/sign-in');
  };

  const toggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isOpen === false) {
      togglerUser?.current?.setAttribute('aria-expanded', 'true');
      navbar?.current?.classList?.add('show');
      togglerUser?.current?.classList?.remove('collapsed');
      togglerUser?.current?.classList?.add('active');
      setOpen(true);
    } else {
      togglerUser?.current?.setAttribute('aria-expanded', 'false');
      togglerUser?.current?.classList?.remove('active');
      togglerUser?.current?.classList?.add('collapsed');
      navbar?.current?.classList?.remove('show');
      setOpen(false);
    }
  };
  return (
    <nav
      className="navbar navbar-default"
      id="navbar_user"
      style={{ backgroundColor: '#fdfdfd' }}
    >
      <div className="container-fluid">
        <div className="navbar-header">
          <p style={{ marginTop: '10px', color: 'white' }}>
            <img
              src="/assets/img/habitat-coins-black.png"
              alt="Habitat coins"
              style={{
                height: '1.5rem',
                width: '1.5rem',
                marginLeft: '1rem',
              }}
            />
            <strong style={{ marginLeft: '1rem', color: 'black' }}>
              {user?.points.toLocaleString('es-ES')}
            </strong>
          </p>
        </div>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbar"
          aria-label="toggle navigation"
          aria-expanded="false"
          onClick={toggle}
          id="togglerUser"
          ref={togglerUser}
        >
          <span className="toggler-icon" style={{ backgroundColor: 'black' }} />
          <span className="toggler-icon" style={{ backgroundColor: 'black' }} />
          <span className="toggler-icon" style={{ backgroundColor: 'black' }} />
        </button>
        <div className="collapse navbar-collapse" id="navbar" ref={navbar}>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link href="/user" style={{ color: 'white' }}>
                Perfil
              </Link>
            </li>
            <li className="nav-item">
              <Link href="/user/exchange" style={{ color: 'white' }}>
                Canjear
              </Link>
            </li>
            <li className="nav-item">
              <Link href="/user/request" style={{ color: 'white' }}>
                Solicitudes
              </Link>
            </li>
            <li className="nav-item">
              <Link href="/products" style={{ color: 'white' }}>
                Productos
              </Link>
            </li>

            <li className="nav-item">
              <a
                onClick={onClick}
                style={{ color: 'white' }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              >
                Salir
              </a>
            </li>
          </ul>
        </div>
        <a
          role="button"
          onClick={onClick}
          style={{ color: 'black', right: '30px' }}
          id="logout"
          tabIndex={0}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        >
          <span>
            <FaSignOutAlt style={{ color: 'black', marginRight: '0.5rem' }} />
          </span>
          Salir
        </a>
      </div>
    </nav>
  );
}

export default NavbarUser;
